<template>
    <div id="test6">
        <div class="crumbs">垃圾分类 / 巡查情况</div>
        <div class="center">
            <div class="table">
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="id" label="编号" width="50" :align="'center'">
                    </el-table-column>
                    <el-table-column label="事件类型" :align="'center'">
                        <template slot-scope="{row}">
                            <div>
                                {{ typearr[row.type] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="事件描述" :align="'center'">
                    </el-table-column>
                    <el-table-column label="上报时间" prop="c_time" :align="'center'">
                    </el-table-column>

                    <el-table-column label="是否处理" :align="'center'">
                        <template slot-scope="{row}">
                            <div>
                                {{ row.status == 0 ? "未处理" : "已处理" }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <span style="float: right; margin-top: 20px;margin-right: 20px;">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page" :page-sizes="[1000]" :page-size="limit"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length" background>
                    </el-pagination>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { getEventListWeb } from "../request/api"
export default {
    name: 'DemoTest6',
    data() {
        return {
            tableData: [],
            page: 1,//当前页
            limit: 10,//一页展示几条
            total: 0,
            typearr: ["", "乱丢乱扔", "冒烟起火", "故障维修", "其他", "环境卫生"]
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        async getList() {
            let res = await getEventListWeb()
            console.log(res.data);
            this.tableData = res.data
        },
        onSubmit() {
            console.log('submit!');
        },
        async handleSizeChange(val) {
            this.limit = val
            this.getstaffPunchLog()

        },
        async handleCurrentChange(val) {
            this.page = val
            this.getstaffPunchLog()
        },


    },
};
</script>

<style lang="less" scoped>
#test6 {
    width: 100%;
    height: 100vh;

}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.center {
    width: 100vw;
    height: calc(100% - 31px);
    // background-color: #000;
    margin: auto;




    .center_top {
        padding-top: 10px;
        padding-bottom: 13px;
        height: 36px;
        display: flex;
        justify-content: center;

        ::v-deep .el-input__inner {
            width: 190px;
            height: 32px;
        }
    }

    .table {
        height: calc(100vh - 100px);
        overflow: auto;
        padding: 0 10px;
    }
}
</style>